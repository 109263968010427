<script setup lang="ts">
import {
  ref,
  watchOnce,
  watch,
  useAsyncData,
  useGeneralService,
  useFooterConfig,
  useNavigation,
  useConfigStore,
  useElementVisibility,
  useDevice,
} from '#imports';

const {
  navLinks,
  socmedLinks,
  storeLinks,
  showGlobalHolidayLicense,
  showAustraliaLicense,
  onClickDownloadBanner,
  onClickSocialMedia,
} = useFooterConfig();
const { navigationPath } = useNavigation();
const config = useConfigStore();
const device = useDevice();
const img = useImage();

const footer = ref(null);

const isFooterVisible = useElementVisibility(footer);

const { data: availablePayments, execute: fetchAvailablePayments } = useAsyncData(
  () => useGeneralService().getAvalaiblePaymentMethods({ currency: config.currency! }),
  {
    server: device.isCrawler,
    immediate: device.isCrawler,
    transform: (data) => data.result.payments,
  },
);

watchOnce(isFooterVisible, () => fetchAvailablePayments());

watch(
  () => config.currency,
  () => fetchAvailablePayments(),
);
</script>

<template>
  <footer ref="footer">
    <div class="bg-whiter">
      <div
        class="container py-30"
        data-testid="accepted-payment-method"
      >
        <div class="text-medium font-semibold mb-15">
          {{ $t('global.paymentacceptedmethod') }}
        </div>

        <div class="w-[70%] flex flex-wrap gap-10">
          <div
            v-for="(payment, idx) in availablePayments"
            :key="idx"
            class="w-[80px] h-[31px]"
          >
            <NuxtImg
              :alt="payment.alt"
              :src="payment.url"
              width="80"
              height="31"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white pt-30 pb-[90px]">
      <div class="container flex">
        <div class="flex flex-col items-start mr-[100px]">
          <NuxtLink
            :to="navigationPath('/contact')"
            external
            no-rel
          >
            <NuxtImg
              data-testid="footer-contact-logo"
              src="/img/logo_footer.png"
              width="140"
              height="56"
              sizes="lg:50vw"
              alt="Airpaz contact"
              loading="lazy"
            />
          </NuxtLink>

          <NuxtImg
            src="/img/digicert.png"
            width="97"
            height="40"
            sizes="lg:50vw"
            alt="Digicert"
            loading="lazy"
            class="mt-20"
          />

          <NuxtImg
            src="/img/visa_verified.png"
            width="91"
            height="40"
            sizes="lg:50vw"
            alt="Visa verified"
            loading="lazy"
            class="mt-20"
          />

          <NuxtLink
            href="https://www.certipedia.com/quality_marks/0000085717"
            rel="nofollow"
            target="_blank"
          >
            <NuxtImg
              src="/img/logo_pcidss.png"
              width="96"
              height="49"
              sizes="lg:50vw"
              alt="PCIDSS"
              loading="lazy"
              class="mt-20"
            />
          </NuxtLink>
        </div>

        <nav
          v-for="nav in navLinks"
          :key="nav.section"
          class="flex-1"
        >
          <h3
            class="text-medium font-bold mb-20"
            translate="no"
          >
            {{ nav.section }}
          </h3>

          <ul>
            <li
              v-for="link in nav.links"
              :key="link.text"
              class="my-10 first:mt-0 last:mb-0"
              :data-testid="'footer-' + link.text + '-textButton'"
            >
              <NuxtLink
                :to="link.to"
                external
                no-rel
                class="text-base underline-offset-2 hover:underline"
              >
                {{ link.text }}
              </NuxtLink>
            </li>
          </ul>
        </nav>

        <div class="mr-[80px]">
          <h3
            class="text-medium font-bold mb-20"
            translate="no"
          >
            {{ $t('global.followus') }}
          </h3>

          <div class="grid grid-cols-3 gap-10">
            <NuxtLink
              v-for="link in socmedLinks"
              :key="link.id"
              :to="link.to"
              rel="nofollow"
              @click="onClickSocialMedia(link.id)"
            >
              <div
                :data-testid="'soscialMedia-' + link.id + '-logo'"
                class="flex items-center justify-center text-white text-[13px] rounded-full h-[25px] w-[25px] bg-gray-dark hover:bg-gray-darkest"
              >
                <ApzIcon :icon="['fab', link.icon]" />
              </div>
            </NuxtLink>
          </div>
        </div>

        <div>
          <h3
            class="text-medium font-bold mb-20"
            translate="no"
          >
            {{ $t('global.downloadapp') }}
          </h3>

          <NuxtLink
            v-for="store in storeLinks"
            :key="store.img.src"
            :to="store.link"
            target="_blank"
            rel="nofollow"
            :data-testid="'download-' + store.img.src + '-button'"
            class="block my-10 first:mt-0 last:mb-0"
            @click="onClickDownloadBanner(store.event)"
          >
            <NuxtImg
              :src="'/img/' + store.img.src"
              :alt="store.alt"
              width="108"
              height="33"
              loading="lazy"
              sizes="lg:219px"
              :placeholder="
                device.isCrawler
                  ? undefined
                  : img('/img/' + store.img.fallback, {
                      sizes: 'lg:219px',
                      modifiers: {
                        format: 'webp',
                        width: 108,
                        height: 33,
                      },
                    })
              "
            />
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div
        class="container text-gray-dark text-center border-t border-gray-lightest py-20"
        data-testid="footer-copyright-text"
      >
        <div class="text-base">
          {{ $t('global.copyrighttitle') }}
        </div>
        <div
          v-if="showGlobalHolidayLicense"
          class="text-small mt-5"
        >
          Global Airlines Holiday Sdn Bhd 105929-H
        </div>
        <div
          v-else-if="showAustraliaLicense"
          class="text-small mt-5"
        >
          <div>Airpaz Australia Pty Ltd , 21 Dunkerley Pl , Waterloo, NSW, 2017 , Australia</div>
          <NuxtLink
            :to="navigationPath('/privacy-policy')"
            external
            no-rel
            class="underline mt-5"
          >
            {{ $t('global.privacypolicy') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>
